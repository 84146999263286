import * as React from 'react';

interface Props {
  className?: string;
}

export default function RacketBall({
  className,
  viewBox = '0 0 24 24',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="4" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6132 13.109C18.0123 8.85999 17.2202 3.79966 13.8439 1.80645C10.4677 -0.186757 5.7859 1.64195 3.3868 5.89098C0.987709 10.14 1.77983 15.2003 5.15605 17.1936C8.53228 19.1868 13.2141 17.3581 15.6132 13.109ZM5.55878 7.08113V8.1916H6.64476V7.08113H5.55878ZM6.64476 10.4125V9.30207H7.73075V10.4125H6.64476ZM9.90272 8.1916V9.30207H10.9887V8.1916H9.90272ZM7.73075 5.97066V4.86019H8.81674V5.97066H7.73075ZM12.0747 3.74973V4.86019H13.1607V3.74973H12.0747ZM13.1607 11.523V10.4125H14.2467V11.523H13.1607ZM10.9887 12.6335V13.7439H12.0747V12.6335H10.9887ZM8.81674 13.7439V14.8544H9.90272V13.7439H8.81674ZM8.81674 11.523V12.6335H9.90272V11.523H8.81674ZM6.64476 13.7439V14.8544H7.73075V13.7439H6.64476ZM6.64476 12.6335V11.523H7.73075V12.6335H6.64476ZM4.47279 11.523V12.6335H5.55878V11.523H4.47279ZM4.47279 10.4125V9.30207H5.55878V10.4125H4.47279Z"
        fill="currentColor"
      />
      <path
        d="M2.75 21.7632L5.5 17"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
