import * as React from 'react';

interface Props {
  className?: string;
}

const UserCircle: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5999 11.9999C21.5999 17.3018 17.3018 21.5999 11.9999 21.5999C6.69796 21.5999 2.3999 17.3018 2.3999 11.9999C2.3999 6.69797 6.69796 2.3999 11.9999 2.3999C17.3018 2.3999 21.5999 6.69797 21.5999 11.9999ZM14.3999 8.3999C14.3999 9.72539 13.3254 10.7999 11.9999 10.7999C10.6744 10.7999 9.59989 9.72539 9.59989 8.3999C9.59989 7.07442 10.6744 5.9999 11.9999 5.9999C13.3254 5.9999 14.3999 7.07442 14.3999 8.3999ZM11.9998 13.1999C9.57875 13.1999 7.49262 14.6338 6.54438 16.6988C7.86472 18.2303 9.81907 19.1999 11.9999 19.1999C14.1806 19.1999 16.1349 18.2304 17.4553 16.6989C16.507 14.6339 14.4209 13.1999 11.9998 13.1999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserCircle;
