import * as React from 'react';

export default function Home({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.56577 1.83392C8.25335 1.5215 7.74682 1.5215 7.4344 1.83392L1.83441 7.43392C1.52199 7.74634 1.52199 8.25288 1.83441 8.56529C2.14683 8.87771 2.65336 8.87771 2.96578 8.56529L3.2001 8.33098V13.5996C3.2001 14.0414 3.55827 14.3996 4.00009 14.3996H5.60009C6.04192 14.3996 6.40009 14.0414 6.40009 13.5996V11.9996C6.40009 11.5578 6.75826 11.1996 7.20009 11.1996H8.80009C9.24191 11.1996 9.60009 11.5578 9.60009 11.9996V13.5996C9.60009 14.0414 9.95826 14.3996 10.4001 14.3996H12.0001C12.4419 14.3996 12.8001 14.0414 12.8001 13.5996V8.33098L13.0344 8.56529C13.3468 8.87771 13.8533 8.87771 14.1658 8.56529C14.4782 8.25288 14.4782 7.74634 14.1658 7.43392L8.56577 1.83392Z"
        fill="currentColor"
      />
    </svg>
  );
}
