import React from 'react';
import { tv } from 'tailwind-variants';
import Link from 'components/Link';
import type { ButtonLinkTextProps, ButtonProps, ButtonTextProps, LinkProps } from './types';

export const buttonVariants = tv({
  base: 'items-center justify-center rounded-full text-center disabled:cursor-not-allowed disabled:opacity-50 transition-colors',
  /**
   * @todo do we need shrink-0?
   */
  // base: 'shrink-0 items-center justify-center rounded-full text-center disabled:cursor-not-allowed disabled:opacity-50 transition-colors',
  variants: {
    size: {
      icon: 'flex items-center justify-center p-0',
      xs: 'typography-product-button-label-xs px-ds-md py-1.5',
      sm: 'typography-product-button-label-small px-ds-lg py-2',
      md: 'typography-product-button-label-medium px-ds-xl py-2',
      lg: 'typography-product-button-label-large px-ds-2xl py-4',
    },
    sizeMobile: {
      icon: 'flex items-center justify-center p-0',
      xs: 'typography-product-button-label-xs px-ds-md py-1.5',
      sm: 'typography-product-button-label-small px-ds-lg py-2',
      md: 'typography-product-button-label-medium px-ds-xl py-2',
      lg: 'typography-product-button-label-large px-ds-2xl py-4',
    },
    sizeDesktop: {
      icon: 'flex items-center justify-center p-0',
      xs: 'lg:typography-product-button-label-xs lg:px-ds-md lg:py-1.5',
      sm: 'lg:typography-product-button-label-small lg:px-ds-lg lg:py-2',
      md: 'lg:typography-product-button-label-medium lg:px-ds-xl lg:py-2',
      lg: 'lg:typography-product-button-label-large lg:px-ds-2xl lg:py-4',
    },
    variant: {
      brand:
        'border border-color-brand-primary bg-color-brand-primary text-color-text-darkmode-primary hover:bg-brand-fire-600 hover:border-brand-fire-600 active:bg-brand-fire-700 active:border-brand-fire-700',
      'brand-secondary':
        'border border-color-brand-primary bg-transparent text-color-text-brand hover:text-brand-fire-600 hover:border-brand-fire-600 active:text-brand-fire-400 active:border-brand-fire-400',
      primary:
        'border border-color-button-lightmode bg-color-button-lightmode text-color-text-lightmode-invert dark:border-color-button-darkmode dark:bg-color-button-darkmode dark:text-color-text-darkmode-invert hover:bg-color-bg-lightmode-invert-highlighted active:bg-color-bg-lightmode-invert-secondary dark:hover:bg-color-bg-darkmode-invert-highlighted dark:active:bg-color-bg-darkmode-invert-secondary',
      secondary:
        'border border-color-button-lightmode bg-color-button-darkmode text-color-text-lightmode-primary dark:border-color-button-darkmode dark:bg-color-button-lightmode dark:text-color-text-lightmode-invert hover:bg-color-bg-lightmode-secondary dark:hover:bg-color-bg-darkmode-secondary',
      /**
       * @note this is only used a few places. Is it needed or can it be combined? It's not clear if there is a matching version in the design system https://www.figma.com/design/a59YgLycqFBLkKkV519Udr/Design-System?node-id=314-691&m=dev
       */
      'primary-light':
        'border border-white bg-color-button-darkmode text-color-text-lightmode-primary dark:border-color-button-darkmode dark:bg-color-button-lightmode dark:text-color-text-lightmode-invert',
      inverted:
        'border border-color-button-lightmode bg-color-button-darkmode text-color-text-lightmode-primary dark:border-color-button-lightmode dark:bg-color-button-lightmode dark:text-color-text-lightmode-invert hover:bg-color-bg-lightmode-secondary dark:hover:bg-color-bg-darkmode-secondary',
    },
    inline: {
      true: 'inline-flex w-auto',
      false: 'flex w-full',
    },
    inlineDesktop: {
      true: 'lg:inline-flex lg:w-auto',
      false: 'flex w-full',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'md',
    variant: 'primary',
    inline: false,
    inlineDesktop: false,
  },
});

export const buttonTextVariants = tv({
  base: 'font-medium',
  variants: {
    size: {
      icon: '',
      xs: 'typography-product-button-label-xs',
      sm: 'typography-product-button-label-small',
      md: 'typography-product-button-label-medium',
      lg: 'typography-product-button-label-large',
    },
    sizeMobile: {
      icon: '',
      xs: 'typography-product-button-label-xs',
      sm: 'typography-product-button-label-small',
      md: 'typography-product-button-label-medium',
      lg: 'typography-product-button-label-large',
    },
    sizeDesktop: {
      icon: '',
      xs: 'lg:typography-product-button-label-xs lg:px-4 lg:py-1.5',
      sm: 'lg:typography-product-button-label-small lg:px-4 lg:py-2',
      md: 'lg:typography-product-button-label-medium lg:px-6 lg:py-2',
      lg: 'lg:typography-product-button-label-large lg:px-8 lg:py-4',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'md',
  },
});

export const iconSpacingLeft = tv({
  variants: {
    size: {
      icon: '',
      xs: 'mr-1',
      sm: 'mr-1',
      md: 'mr-2',
      lg: 'mr-2',
    },
    sizeMobile: {
      icon: '',
      xs: 'mr-1',
      sm: 'mr-1',
      md: 'mr-2',
      lg: 'mr-2',
    },
    sizeDesktop: {
      icon: '',
      xs: 'lg:mr-1',
      sm: 'lg:mr-1',
      md: 'lg:mr-2',
      lg: 'lg:mr-2',
    },
  },
});

export const iconSpacingRight = tv({
  variants: {
    size: {
      icon: '',
      xs: 'ml-1.5',
      sm: 'ml-1.5',
      md: 'ml-2',
      lg: 'ml-2',
    },
    sizeMobile: {
      icon: '',
      xs: 'ml-1.5',
      sm: 'ml-1.5',
      md: 'ml-2',
      lg: 'ml-2',
    },
    sizeDesktop: {
      icon: '',
      xs: 'lg:ml-1.5',
      sm: 'lg:ml-1.5',
      md: 'lg:ml-2',
      lg: 'lg:ml-2',
    },
  },
});

const ButtonContent = ({
  children,
  label,
  iconLeft,
  iconRight,
  size,
  sizeMobile,
  sizeDesktop,
}: {
  children?: ButtonProps['children'];
  iconLeft?: ButtonProps['iconLeft'];
  iconRight?: ButtonProps['iconRight'];
  size: ButtonProps['size'];
  sizeMobile: ButtonProps['sizeMobile'];
  sizeDesktop: ButtonProps['sizeDesktop'];
  label?: ButtonProps['label'];
}) => (
  <>
    {!!iconLeft && (
      <span className={iconSpacingLeft({ size, sizeMobile, sizeDesktop })}>{iconLeft}</span>
    )}
    <span>{children || label}</span>
    {!!iconRight && (
      <span className={iconSpacingRight({ size, sizeMobile, sizeDesktop })}>{iconRight}</span>
    )}
  </>
);

export function ButtonLink({
  name,
  label,
  iconLeft,
  iconRight,
  isInline,
  isInlineMobile,
  isInlineDesktop,
  variant,
  size,
  sizeMobile,
  sizeDesktop,
  className,
  children,
  href,
  rel,
  ...rest
}: LinkProps) {
  return (
    <Link
      href={href}
      className={buttonVariants({
        variant,
        size,
        sizeMobile,
        sizeDesktop,
        inline: isInline || isInlineMobile,
        inlineDesktop: isInline || isInlineDesktop,
        className,
      })}
      aria-label={label}
      {...rest}
    >
      <ButtonContent
        iconLeft={iconLeft}
        iconRight={iconRight}
        size={size}
        sizeMobile={sizeMobile}
        sizeDesktop={sizeDesktop}
        label={label}
      >
        {children}
      </ButtonContent>
    </Link>
  );
}

export function Button({
  type = 'button',
  name,
  label,
  iconLeft,
  iconRight,
  isInline,
  isInlineMobile,
  isInlineDesktop,
  variant,
  size,
  sizeMobile,
  sizeDesktop,
  className,
  children,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={buttonVariants({
        variant,
        size,
        sizeMobile,
        sizeDesktop,
        inline: isInline || isInlineMobile,
        inlineDesktop: isInline || isInlineDesktop,
        className,
      })}
      name={name || label}
      aria-label={label || type}
      type={type}
      {...rest}
    >
      <ButtonContent
        iconLeft={iconLeft}
        iconRight={iconRight}
        size={size}
        sizeMobile={sizeMobile}
        sizeDesktop={sizeDesktop}
        label={label}
      >
        {children}
      </ButtonContent>
    </button>
  );
}

export function ButtonText({
  type = 'button',
  className,
  size,
  sizeMobile,
  sizeDesktop,
  children,
  ...rest
}: ButtonTextProps) {
  return (
    <button
      type={type}
      className={buttonTextVariants({
        size,
        sizeMobile,
        sizeDesktop,
        className,
      })}
      {...rest}
    >
      {children}
    </button>
  );
}

export function ButtonLinkText({
  href,
  className,
  size,
  sizeMobile,
  sizeDesktop,
  children,
  ...rest
}: ButtonLinkTextProps) {
  return (
    <Link
      href={href}
      className={buttonTextVariants({
        size,
        sizeMobile,
        sizeDesktop,
        className,
      })}
      {...rest}
    >
      {children}
    </Link>
  );
}
