export const getTimezone = (preferredTimezone?: string | null) => {
  try {
    if (preferredTimezone) {
      return preferredTimezone;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  } catch (error) {
    return '';
  }
};
