import * as React from 'react';

export default function Trophy({
  className,
  viewBox = '0 0 24 24',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5H17V3H7V5H5C3.9 5 3 5.9 3 7V8C3 10.55 4.92 12.63 7.39 12.94C8.02 14.44 9.37 15.57 11 15.9V19H7V21H17V19H13V15.9C14.63 15.57 15.98 14.44 16.61 12.94C19.08 12.63 21 10.55 21 8V7C21 5.9 20.1 5 19 5ZM7 10.82C5.84 10.4 5 9.3 5 8V7H7V10.82ZM19 8C19 9.3 18.16 10.4 17 10.82V7H19V8Z"
        fill="currentColor"
      />
    </svg>
  );
}
