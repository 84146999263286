import * as React from 'react';

export default function Players({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} fill="none">
      <path
        d="M10.3998 4.79844C10.3998 6.12392 9.32528 7.19844 7.9998 7.19844C6.67432 7.19844 5.5998 6.12392 5.5998 4.79844C5.5998 3.47295 6.67432 2.39844 7.9998 2.39844C9.32528 2.39844 10.3998 3.47295 10.3998 4.79844Z"
        fill="currentColor"
      />
      <path
        d="M14.3998 6.39844C14.3998 7.28209 13.6835 7.99844 12.7998 7.99844C11.9161 7.99844 11.1998 7.28209 11.1998 6.39844C11.1998 5.51478 11.9161 4.79844 12.7998 4.79844C13.6835 4.79844 14.3998 5.51478 14.3998 6.39844Z"
        fill="currentColor"
      />
      <path
        d="M11.1998 11.9984C11.1998 10.2311 9.76711 8.79844 7.9998 8.79844C6.23249 8.79844 4.7998 10.2311 4.7998 11.9984V14.3984H11.1998V11.9984Z"
        fill="currentColor"
      />
      <path
        d="M4.7998 6.39844C4.7998 7.28209 4.08346 7.99844 3.1998 7.99844C2.31615 7.99844 1.5998 7.28209 1.5998 6.39844C1.5998 5.51478 2.31615 4.79844 3.1998 4.79844C4.08346 4.79844 4.7998 5.51478 4.7998 6.39844Z"
        fill="currentColor"
      />
      <path
        d="M12.7998 14.3984V11.9984C12.7998 11.1551 12.5823 10.3626 12.2004 9.6739C12.392 9.62464 12.5928 9.59844 12.7998 9.59844C14.1253 9.59844 15.1998 10.673 15.1998 11.9984V14.3984H12.7998Z"
        fill="currentColor"
      />
      <path
        d="M3.79921 9.6739C3.41728 10.3626 3.1998 11.1551 3.1998 11.9984V14.3984H0.799805V11.9984C0.799805 10.673 1.87432 9.59844 3.1998 9.59844C3.40677 9.59844 3.60762 9.62464 3.79921 9.6739Z"
        fill="currentColor"
      />
    </svg>
  );
}
