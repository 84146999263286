import * as React from 'react';

export default function RacketTwo({
  className,
  viewBox = '0 0 25 24',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2402 14.0015C16.4199 10.1411 15.7002 5.5436 12.6328 3.73269C9.56535 1.92179 5.31173 3.58323 3.13206 7.44364C0.952396 11.304 1.67207 15.9015 4.7395 17.7125C7.80692 19.5234 12.0605 17.8619 14.2402 14.0015ZM5.10538 8.52494V9.53384H6.09204V8.52494H5.10538ZM6.09204 11.5516V10.5427H7.0787V11.5516H6.09204ZM9.05202 9.53384V10.5427H10.0387V9.53384H9.05202ZM7.0787 7.51604V6.50713H8.06536V7.51604H7.0787ZM11.0253 5.49823V6.50713H12.012V5.49823H11.0253ZM12.012 12.5605V11.5516H12.9987V12.5605H12.012ZM10.0387 13.5695V14.5784H11.0253V13.5695H10.0387ZM8.06536 14.5784V15.5873H9.05202V14.5784H8.06536ZM8.06536 12.5605V13.5695H9.05202V12.5605H8.06536ZM6.09204 14.5784V15.5873H7.0787V14.5784H6.09204ZM6.09204 13.5695V12.5605H7.0787V13.5695H6.09204ZM4.11872 12.5605V13.5695H5.10538V12.5605H4.11872ZM4.11872 11.5516V10.5427H5.10538V11.5516H4.11872Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.98567 22.8477C1.44248 22.534 1.25637 21.8395 1.56998 21.2963L4.06846 16.9688C4.38207 16.4256 5.07664 16.2395 5.61982 16.5531C6.163 16.8667 6.34911 17.5613 6.0355 18.1045L3.53703 22.432C3.22342 22.9752 2.52885 23.1613 1.98567 22.8477Z"
        fill="currentColor"
      />
      <path d="M9.05202 10.5427V9.53384H10.0387V10.5427H9.05202Z" fill="currentColor" />
      <path d="M10.0387 14.5784V13.5695H11.0253V14.5784H10.0387Z" fill="currentColor" />
      <path d="M11.0253 6.50713V5.49823H12.012V6.50713H11.0253Z" fill="currentColor" />
      <path d="M12.012 12.5605V11.5516H12.9987V12.5605H12.012Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3102 16.3484C21.4499 15.1623 22.4355 11.7513 20.5884 8.41906C18.7413 5.0868 15.3263 4.11507 13.1866 5.30113C11.0469 6.4872 10.0613 9.8982 11.9084 13.2305C13.7555 16.5627 17.1705 17.5345 19.3102 16.3484ZM20.185 17.9266C23.4536 16.1148 24.3407 11.4665 22.1666 7.54426C19.9925 3.62204 15.5804 1.91118 12.3118 3.72295C9.04331 5.53472 8.15613 10.183 10.3303 14.1053C12.5044 18.0275 16.9165 19.7383 20.185 17.9266Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3857 22.4188L18.98 18.0788C18.678 17.534 18.8748 16.8476 19.4196 16.5457C19.9643 16.2437 20.6507 16.4405 20.9527 16.9853L23.3584 21.3253C23.6604 21.87 23.4635 22.5564 22.9188 22.8584C22.374 23.1604 21.6876 22.9635 21.3857 22.4188Z"
        fill="currentColor"
      />
      <path d="M8.06536 13.5695V12.5605H9.05202V13.5695H8.06536Z" fill="currentColor" />
    </svg>
  );
}
