import * as React from 'react';
import { useGetUserUnreadNotificationCountLazyQuery } from 'types/generated/client';
import { useViewer } from 'hooks/useViewer';
import Bell from 'svg/Bell';
import { ButtonText } from 'components/Button';

const REFETCH_TIME_MS = 30_000;

interface Props {
  className?: string;
  onClick: () => void;
}

const NotificationIndicator: React.FC<Props> = ({ className, onClick }) => {
  const viewer = useViewer();
  const [getUserUnreadNotificationCountLazyQuery, { data, startPolling, stopPolling }] =
    useGetUserUnreadNotificationCountLazyQuery();
  const hasNotifications = !!data?.userNotificationsAggregate.aggregate?.count;

  React.useEffect(() => {
    if (viewer.userId) {
      getUserUnreadNotificationCountLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
          userId: viewer.userId,
        },
      });
      startPolling(REFETCH_TIME_MS);
    }

    return () => stopPolling();
  }, [viewer.userId]);

  return (
    <div className="relative flex shrink-0 items-center justify-end text-base">
      <ButtonText className="flex items-center justify-center rounded-full" onClick={onClick}>
        <Bell className={className} />
        {hasNotifications && (
          <div className="absolute right-0.5 top-0 h-2 w-2 rounded-full bg-color-bg-lightmode-brand dark:bg-color-bg-darkmode-brand">
            &nbsp;
          </div>
        )}
      </ButtonText>
    </div>
  );
};

export default NotificationIndicator;
